import { httpGet, httpPut } from "@/api/request";
import $store from "@/store";

// 获取在线电杆列表
export function GET_EQUIPMENT_ONLINE_LIST(params) {
    return httpGet("/online", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取智能终端设备列表
export function GET_EQUIPMENT_TERMINAL_LIST(params) {
    return httpGet("/terminal", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}
